import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import img1 from "../../../assets/banner/banner1.webp";
import img2 from "../../../assets/banner/banner2.webp";
import img3 from "../../../assets/banner/banner3.webp";
import img4 from "../../../assets/banner/banner4.webp";


export default function BannerHome() {
  return (
    <Carousel fade controls={false} interval={3000}>
      <Carousel.Item>
        <a href="https://expo.gruporequiez.com/" target="_blank">
          <img
            className="w-100"
            src={img1}
            alt="First slide"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a href="https://www.labenze.com" target="_blank">
        <img
          className="w-100"
          src={img2}
          alt="Second slide"
          />
          </a>
      </Carousel.Item>
      <Carousel.Item>
          <img
            className="w-100"
            src={img3}
            alt="Third slide"
          />
      </Carousel.Item>
      <Carousel.Item>
        <a href="/sustentability" >
          <img
            className="w-100"
            src={img4}
            alt="Fifth slide"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}
